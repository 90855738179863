// Jquery functions
(function($) {
	// grid
	baselineGrid();
	// LazyLoading
	//img
	$("[data-src]").on("inview", function() {
		var $this = $(this);
		$this.attr("src", $this.attr("data-src"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-src");
	});
	$("[data-srcset]").on("inview", function() {
		var $this = $(this);
		$this.attr("srcset", $this.attr("data-srcset"));
		// Remove it from the set of matching elements in order to avoid that the handler gets re-executed
		$this.removeAttr("data-srcset");
	});
	var $someImages = $('.obj-fit');
	objectFitImages($someImages);
	$("[data-target]").click(function() {
		var targetElement = $($(this).data("target"));
		var targetBottomOffset = $(this).data("bottom-offset");
		var targetTopOffset = $(this).data("top-offset");
		moveToHtmlTarget(targetElement, targetTopOffset, targetBottomOffset);
	});
	function moveToHtmlTarget(element, topOffset, bottomOffset) {
		var offset = element.offset().top;

		if (topOffset > 0) {
			offset = offset - topOffset;
		}

		if (bottomOffset > 0) {
			offset = offset + bottomOffset;
		}

		$("html, body").animate(
			{
				scrollTop: offset
			},
			1000
		);

		return this;
	}
	// iframe and table (wysiwyg editor)
	$(".entry-content iframe").each(function() {
		if (
			$(this)
				.attr("src")
				.indexOf("youtube") != -1 ||
			$(this)
				.attr("src")
				.indexOf("vimeo") != -1
		) {
			$(this)
				.addClass("embed-responsive-item")
				.wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
			$(this).removeAttr("width");
			$(this).removeAttr("height");
		}
	});
	$(".entry-content table").each(function() {
		$(this)
			.addClass("table")
			.wrap('<div class="table-responsive"></div>');
	});
	function baselineGrid() {
		var gridToggle = $(".baseline-toggle");

		gridToggle.click(function() {
			$("html").toggleClass("js-baseline");
		});
	}
	// nav open/close
	$("#nav-opener").click(function(){
		$(this).toggleClass("is-active");
		$("#nav-container").toggleClass("is-active");
		if ($(this).hasClass("is-active")) {
			$.scrollLock(true);
		} else {
			$.scrollLock(false);
		}
	});
	// lang
	$(".lang__active").click(function(){
		$(".lang").toggleClass("is-active");
	});
	$(window).click(function() {
		$(".lang").removeClass("is-active");
	});
	$('.lang').click(function(event){
		event.stopPropagation();
	});
	$(".services__tab-opener").click(function(){
		$(this).parent().siblings().find("div").removeClass("is-active");
		$(this).parent().siblings().find(".entry-content").slideUp();
		$(this).parent().find(".entry-content").slideToggle();
		if ( $(this).hasClass("is-active") ) {
			$(this).removeClass("is-active");
		} else {
			$(this).addClass("is-active");
		}
	});
	$('.premise__box').matchHeight();
	$('.contact').matchHeight({
		byRow: false
	});
})( jQuery );
